<template>
  <transition name="van-slide-right">
    <div v-show="visible">
      <NavBar/>
      <div class="head">
        <h3>{{ keepAccounts.feeTypeName }}</h3>
        <h3 style="color: #e33d33" v-if="keepAccounts.paymentType===0">￥ +{{ keepAccounts.money  }}</h3>
        <h3 v-if="keepAccounts.paymentType===1">￥ -{{ keepAccounts.money }}</h3>
        <p style="font-size: 14px;color: #333333">记录时间：{{ keepAccounts.keepTimeDay }}</p>
        <p>
          <van-tag plain type="danger" v-if="keepAccounts.status === 0"> 未提交</van-tag>
        </p>
        <p>
          <van-tag plain type="success" v-if="keepAccounts.status === 1"> 已提交</van-tag>
        </p>
        <p>
          <van-tag plain type="primary" v-if="keepAccounts.status === 2"> 已纳税</van-tag>
        </p>
        <div style="padding: 0 20px 0 20px;">
          <van-field
              readonly
              clickable
              label="业务合同"
              name="contractName"
              :value="keepAccounts.contractName"
              placeholder="请选择业务合同"
              @click="showPicker = true" v-if="keepAccounts.status===0"/>
          <van-popup v-model="showPicker" round position="bottom">
            <van-picker
                show-toolbar
                :columns="contracts"
                @cancel="showPicker = false"
                @confirm="onConfirm"
            />
          </van-popup>
          <van-field v-if="keepAccounts.contractId!=null && keepAccounts.status!==0" label="业务合同" :value="keepAccounts.contractName" readonly/>
          <van-field v-if="keepAccounts.contractId==null && keepAccounts.status!==0" label="业务合同" value="无业务合同" readonly/>
        </div>
      </div>
      <div style="padding-top: 10px">
        <van-divider content-position="left">费用单据</van-divider>
        <div v-if="feeList.length > 0">
          <van-grid :border="false" :column-num="3" :gutter="10">
            <van-grid-item v-for="(item,index) in feeList" :key="item.id">
              <van-image :src=item.fileUrl style="object-fit: contain;height: 60px" @click="imagePreview(index,0)"/>
            </van-grid-item>
          </van-grid>
        </div>
        <div v-else style="text-align: center;color: #bfbfbf"><i class="iconfont icon-meiyoushuju"
                                                                 style="font-size: 100px"></i>
          <p style="font-size: 12px">没有数据</p></div>
        <van-divider content-position="left">发票</van-divider>
        <div v-if="invoiceList.length > 0">
          <van-grid :border="false" :column-num="3" :gutter="10">
            <van-grid-item v-for="(item,index) in invoiceList" :key="item.id">
              <van-image :src=item.fileUrl style="object-fit: contain;height: 60px" @click="imagePreview(index,1)"/>
            </van-grid-item>
          </van-grid>
        </div>
        <div v-else style="text-align: center;color: #bfbfbf"><i class="iconfont icon-meiyoushuju"
                                                                 style="font-size: 100px"></i>
          <p style="font-size: 12px">没有数据</p></div>
        <van-divider content-position="left">业务佐证</van-divider>
        <div v-if="epiList.length > 0">
          <van-grid :border="false" :column-num="3" :gutter="10">
            <van-grid-item v-for="(item,index) in epiList" :key="item.id">
              <van-image :src=item.fileUrl style="object-fit: contain;height: 60px" @click="imagePreview(index,2)"/>
            </van-grid-item>
          </van-grid>
        </div>
        <div v-else style="text-align: center;color: #bfbfbf"><i class="iconfont icon-meiyoushuju"
                                                                 style="font-size: 100px"></i>
          <p style="font-size: 12px">没有数据</p></div>
      </div>
      <div style="padding: 20px 10px 30px 10px;text-align: center">
        <van-button round type="danger" style="width: 40%;margin-right: 5%" v-if="keepAccounts.status === 0"
                    @click="delKeepAccounts">删除
        </van-button>
        <van-button round type="info" style="width: 40%;margin-left: 5%" v-if="keepAccounts.status === 0" :to="{path:'/updateCost',query:{keepAccountsId:keepAccounts.id}}">编辑
        </van-button>
      </div>
    </div>
  </transition>
</template>

<script>
import NavBar from "@/components/NavBar";
import {delKeepAccounts, getKeepAccounts, updKeepAccountsContract} from "@/api/cost/cost";
import {ImagePreview} from 'vant';
import VantMessage from "@/utils/vantMessageUtils";
import {getKeepContractList} from "@/api/comm/comm";

export default {
  name: "cost",
  data() {
    return {
      visible: false,
      keepAccounts: [],
      epiList: [],
      feeList: [],
      invoiceList: [],
      contractShow: false,
      value: '',
      showPicker: false,
      contracts: [],
    };
  },
  components: {
    NavBar,
  },
  methods: {
    async onConfirm(value) {
      this.keepAccounts.contractId = value.id;
      this.keepAccounts.contractName = value.text;
      this.showPicker = false;
      let res = await updKeepAccountsContract({
        keepAccountsId:this.keepAccounts.id,
        contractId:this.keepAccounts.contractId
      })
    },
    async getKeepAccounts(id) {
      let res = await getKeepAccounts({keepAccountsId: id})
      if (res) {
        this.epiList = res.epiList
        this.feeList = res.feeList
        this.invoiceList = res.invoiceList
        this.keepAccounts = res.keepAccounts
        if (res.keepAccounts.contractId === null) {
          this.contractShow = true;
        }
      }
    },
    imagePreview(index, type) {
      let img = [];
      if (type === 0) {
        img = this.feeList;
      } else if (type === 1) {
        img = this.invoiceList;
      } else {
        img = this.epiList;
      }
      let imgFile = []
      for (let i = 0; i < img.length; i++) {
        imgFile.push(img[i].fileUrl)
      }
      ImagePreview({
        images: imgFile,
        startPosition: index,
      });
    },
    delKeepAccounts() {
      VantMessage.confirmMsg("你确定要删除该记录吗？").then(async resolve => {
        if (resolve) {
          let res = await delKeepAccounts({
            keepAccountsId: this.keepAccounts.id
          })
          if (res) {
            VantMessage.successMsg("删除成功")
            let this_ = this;
            setTimeout(() => {
              this_.$router.replace("/home");
            }, 1000);
          } else {
            VantMessage.errorMsg("删除失败，请稍后再试")
          }
        }
      })
    },
    //查询合同
    async getKeepContractList() {
      let res = await getKeepContractList(null);
      if (res.length > 0) {
        for (let i = 0; i < res.length; i++) {
          this.contracts.push({
            text: res[i].contractName,
            id: res[i].id
          })
        }
      }
    }
  },
  mounted() {
    this.visible = true
    this.getKeepAccounts(this.$route.query.keepAccountsId)
    this.getKeepContractList();
    document.title = this.$route.meta.title
  }
}
</script>

<style scoped>
.head {
  /*margin-top: 10px;*/
  padding-top: 10px;
  text-align: center;
  width: 100vw;
  height: 250px;
  /*border-radius: 0 0 30px 30px;*/
  background-color: #FFF;
  /*box-shadow: 0px 15px 10px -15px;*/
}

.demo-lazyload {
  box-sizing: border-box;
  width: 100%;
  height: 250px;
  margin-bottom: 16px;
  padding: 16px;
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 12px;
}

.custom-image .van-empty__image {
  width: 20px !important;
  height: 20px;
}
</style>
